
<template>

  <div>
    <div class="contents">
      <div class="uk-background-fixed uk-background-center-center parallax-bg" uk-scrollspy="cls:  uk-animation-slide-bottom-small; target: .main-card ,.sub-card; delay: 300; repeat: true">
        <div class="main-card"  >
          <Breadcrumb class="uk-margin-left" />
          <div class="sub-card-top"  >
            <page-title></page-title>
            <div class="parent " >
              <div >
                <p class="text" style="font-size: 1.4rem;">
                  高級車（中古車）を購入する際に注意するべきポイントとは？お買い得なプレミアムカーをご紹介
                </p>
              </div>
              <div class="button-area">
                 <a href="https://www.worldingsysdom.com/stock_info/list/car" class="btn"><span>＞＞＞在庫一覧はこちら</span></a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            高級車を中古車として購入する際は、いくつか注意しておきたいポイントがあります。特に、購入後の維持費や運転のしやすさは必ず確認しておくことをおすすめします。プレミアムカーを購入したい方は、car shop MIXをご利用ください。
            <div class="section-title">
              <span >中古車購入時の注意点とは？日産やホンダのプレミアムカーをご紹介！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      中古車は、乗り方や扱い方によって状態が大きく異なります。また、部品の劣化具合やオプションの有無によって、その価値は大きく変動するのです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      特に、高級車を中古車として購入する場合は、「故障しにくい車種か」「販売店のアフターサービスが充実しているか」以外にも、注意するべきポイントがあります。
                    </p>
                    <h3 class="heading-number" data-number="◆">高級車を中古車で購入する際の注意点</h3>
                    <p class="u-m0 u-pb1em">
                      高級車（中古）を購入する際は、次の注意点を理解しておく必要があります。。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>維持費が高額になる可能性がある</h4>
                    <p class="u-m0 u-pb1em">
                      中古車は、新車に比べて維持費が高くなる傾向にあります。特に、高級車のセダンやスポーツカーは、高い走行性能を追求しているモデルが多いことから、車両重量をはじめ、排気量も大きいのが特徴です。そのため、自動車税や自動車重量税などがかさんでしまいます。燃費のよくないモデルも多いため、当然ガソリン代も高くなります。
                    </p>
                    <p class="u-m0 u-pb1em">
                      さらに、高級車は修理が必要となった際のコストが高額になる傾向があります。このように、中古の高級車は、新車に比べて保険料、税金、燃費、メンテナンス費用などが高くなりがちですので、購入後のランニングコストを事前に確認しておくことが重要です。
                    </p>
                    <h4 style="color: #fff;"><b style="color: #c49a00;">■ </b>日本特有の道路に適さないこともある</h4>
                    <p class="u-m0 u-pb1em">
                      外国産の高級車は大型の車種も多く、小回りが利きにくいケースも少なくありません。日本特有の細い道路に適さない場合もありますので、事前にサイズ感を確認しておきましょう。車庫入れなども問題なくこなせるかどうかを確認してみてください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                      <img src="@/assets/img/column-maker-mt/used-car-careful.jpg" alt="Image">
                  </div>
              </div>
              <h3 class="heading-number" data-number="◆">中古での購入がお買い得になっている国産プレミアムカー3選</h3>
              <p class="u-m0 u-pb1em">
                中古車市場で人気の国産プレミアムカーの中でも、お買い得な車種は以下の3つです。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">ホンダ </b>アコード ハイブリッド EX</h4>
              <p class="u-m0 u-pb1em">
                ホンダのアコード ハイブリッド EXは、高い走行性能と燃費効率、豪華なインテリアが特徴の高級車です。ホンダの最新のハイブリッド技術を搭載しており、都市部でも高速道路でも快適なドライビングを実現します。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">日産 </b>スカイライン 350GT ハイブリッド</h4>
              <p class="u-m0 u-pb1em">
                日産のスカイライン 350GT ハイブリッドも、中古車市場で人気の高いモデルです。スカイラインは日産の代表的なスポーツカーで、走行性能の高さとスタイリッシュなデザインは幅広い世代からの支持を集めています。。
              </p>
              <h4 style="color: #fff;"><b style="color: #c49a00;">レクサス </b>LS 600h</h4>
              <p class="u-m0 u-pb1em">
                レクサスのフラッグシップモデルLS 600hは、V8エンジンとハイブリッドシステムを組み合わせている人気の車種です。スムーズな加速と静粛性が特徴で、ラグジュアリーな一台として高い人気を誇ります。
              </p>
              <p class="u-m0 u-mt20 u-pb1em">
                中古車の希少モデルや高級車にご興味のある方は、car shop MIXまでご相談ください。car shop MIXは国産車・外車を問わず幅広いメーカーのプレミアムカー（AT・MT）を取り揃えています。
              </p>
              <p class="u-m0 u-pb1em">
                様々な車種を集めるノウハウがあるため、低走行車や25年規制が解除されるような車もご用意可能です。
              </p>
              <p class="u-m0 u-pb1em">
                また、自動車板金や塗装など、自動車のコンディション維持をお手伝いいたします。自動車に関することでご不明な点がございましたら、お気軽にお問い合わせください。
              </p>
              <div class="c-btn">
                <a class="c-btn-anchor" href="https://www.worldingsysdom.com/stock_info/list/car" >在庫一覧</a>
              </div>
            </div>
          </div>
          <div class="sub-card"  >
            <div class="section-title">
              <span >希少価値の高い中古のプレミアムカーを販売！</span>
            </div>
            <div class="section-main " >
              <div class="uk-flex-top" uk-grid>
                  <div class="uk-width-2-3@m">
                    <p class="u-m0 u-pb1em">
                      リーズナブルな価格で購入しやすい中古車ですが、購入時に気をつけるべきポイントもいくつかあります。その中でも、購入後の維持費や運転のしやすさは必ず確認してください。
                    </p>
                    <p class="u-m0 u-pb1em">
                      コロナ禍で大きく高騰した中古車市場は比較的落ち着いてきましたが、人気の高級車や希少モデルは、投資目的などを理由に今後もさらなる高騰が懸念されます。中古の高級車を購入する場合は、なるべく早いタイミングで検討するのがおすすめです。
                    </p>
                    <p class="u-m0 u-pb1em">
                      中古車の購入を検討されている方は、car shop MIXにお任せください。人気の高級車をはじめ、一般的に市場に出回らないようなプレミアム車種や限定モデルの在庫も多数ございますので、お気に入りの一台を探すことが可能です。
                    </p>
                    <p class="u-m0 u-pb1em">
                      公式サイトの在庫状況では、ご希望の車種の有無をご確認いただけます。万が一希望の車種がない場合でも、豊富なネットワークを活かしてご希望の車種をお探しいたします。中古車に関するご質問などがございましたら、お気軽にお問い合わせください。
                    </p>
                  </div>
                  <div class="uk-width-1-3@m uk-flex-last">
                    <img alt="中古車について案内する人物" src="@/assets/img/column-maker-mt/used-car-guide-person.jpg">
                  </div>
              </div>
              <div class="c-btn">
                <router-link to="/jigyo" class="c-btn-anchor">事業内容</router-link>
              </div>
            </div>
          </div>
          <column-footer :title="'プレミアムカーを販売！日産やホンダなどの人気メーカーの中古車はcar shop MIXへ'" />
        </div>
      </div>
    </div>
  </div>
  
</template>


<script>
import Breadcrumb from "../../../modules/parts/Breadcrumb.vue";
import ColumnFooter from "./Footer.vue";
export default {
  components: {
    Breadcrumb,
    ColumnFooter
  },
}
</script>



<style scoped lang="scss">
.sub-card-top{
  background-image:url(~@/assets/img/column-maker-mt/head-mv2-04.jpg);
}
.parallax-bg {
  background-image:url(~@/assets/img/bg-image3.jpg);
  
}
.access-list{
  *{
    max-height: 100%;
    max-width: 100%;
  }
  iframe{
    width: 60vw;
    height: 50vh;
  }
}

</style>
